/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from "@reduxjs/toolkit";
import DataService from "@untyped-services/dataService";

import { selectActiveEvent } from "@untyped-store/slices/eventsSlice";
import type { EQAToolQuestionState as EQuestionState } from "src/types/events/q&a/q&a.enums";

const dataService = new DataService();

export const loadQuestions = createAsyncThunk(
  "questions/getQuestions",
  async ({ eventId, roomId, states }, thunkAPI) => {
    const params = {
      back_office: "[true,false]",
      include_trashed: true,
      limit: 1000000,
      offset: 0,
    };
    if (states) params.states = `[${states}]`;
    if (roomId) params.questions_room = roomId;

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    const url = `/${eventId}/questions?${queryString}`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

export const loadQuestion = createAsyncThunk(
  "questions/loadQuestion",
  async (questionId, thunkAPI) => {
    const url = `/api/questions/${questionId}`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

export const createQuestion = createAsyncThunk(
  "questions/createQuestion",
  async ({ question }, thunkAPI) => {
    const event = selectActiveEvent(thunkAPI.getState());
    const jwt = thunkAPI.getState().jwt.token;

    return dataService
      .postData(
        `/${event.id}/questions`,
        {
          question,
        },
        jwt,
      )
      .then((res) => res.data);
  },
);

export const updateQuestionOnServer = createAsyncThunk(
  "questions/updateQuestionOnServer",
  async ({ id, changes }, thunkAPI) => {
    const event = selectActiveEvent(thunkAPI.getState());
    const jwt = thunkAPI.getState().jwt.token;

    return dataService
      .putData(
        `/admin/events/${event.id}/questions/${id}`,
        {
          question: changes,
        },
        jwt,
      )
      .then((res) => res.data);
  },
);

type TInitialState = {
  loading: boolean;
  error: string;
  questionToolMode: string;
  showDeletedQuestions: boolean;
  showQuestionForm: boolean;
  shownStates:
    | [EQuestionState.NEW, EQuestionState.LIVE, EQuestionState.ANSWERED]
    | [EQuestionState.LIVE];
  sortByVotes: boolean;
  lockedQuestionId: string | false;
  selectedQuestionId: string | false;
  moderateQuestions: boolean;
};

const questionsAdapter = createEntityAdapter();

const moderateQuestions: boolean =
  typeof appConfig !== "undefined" &&
  appConfig.event &&
  appConfig.event.moderate_questions;

const questionsSelectors = questionsAdapter.getSelectors((slice) => slice);

const initialShownStates = moderateQuestions ? ["0", "200", "300"] : ["200"];
const initialQuestionToolMode = moderateQuestions ? "moderator" : "speaker";

const initialState = questionsAdapter.getInitialState<TInitialState>({
  loading: true,
  error: "",
  questionToolMode: initialQuestionToolMode,
  showDeletedQuestions: false,
  showQuestionForm: false,
  shownStates: initialShownStates,
  sortByVotes: false,
  lockedQuestionId: false,
  selectedQuestionId: false,
  moderateQuestions,
});

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setLockedQuestionId: (state, action: PayloadAction<string | false>) => {
      state.lockedQuestionId = action.payload;
    },
    setSelectedQuestionId: (state, action) => {
      state.selectedQuestionId = action.payload;
    },
    setSortByVotes: (state, action) => {
      state.sortByVotes = action.payload;
    },
    updateQuestion: (state, action) => {
      questionsAdapter.updateOne(state, action);
    },
    addQuestion: (state, action) => {
      questionsAdapter.addOne(state, action);
    },
    updateQuestionToolMode: (state, action) => {
      state.questionToolMode = action.payload;
      if (action.payload == "speaker") state.shownStates = ["200"];
      else if (action.payload == "moderator")
        state.shownStates = ["0", "200", "300"];
    },
    toggleShowDeletedQuestions: (state) => {
      if (state.questionToolMode === "moderator") {
        if (state.shownStates[2] === "300")
          state.shownStates = ["0", "200", "400"];
        else state.shownStates = ["0", "200", "300"];
      }
    },
    toggleShowQuestionForm: (state) => {
      state.showQuestionForm = !state.showQuestionForm;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadQuestions.fulfilled, (state, action) => {
      const { questions } = action.payload;
      questionsAdapter.upsertMany(state, questions);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(loadQuestions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(loadQuestion.fulfilled, (state, action) => {
      questionsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateQuestionOnServer.pending, (state, action) => {
      Util.log("Optimistic Update with action:");
      Util.log(action);
      questionsAdapter.updateOne(state, action.meta.arg);
    });
    builder.addCase(updateQuestionOnServer.rejected, (state, action) => {
      Util.log("Error: update twice");
      Util.log(action.error.message);
    });
    builder.addCase(updateQuestionOnServer.fulfilled, (state, action) => {
      questionsAdapter.upsertOne(state, action.payload);
    });
  },
});

const getQuestionSlice = (state) => state.questions;

export const selectQuestions = createSelector(getQuestionSlice, (slice) =>
  questionsSelectors.selectAll(slice),
);

export const selectLockedQuestionId: (state: any) => string | false = (state) =>
  state.questions.lockedQuestionId;

export const selectSortByVotes = (state) => state.questions.sortByVotes;

export const selectQuestionsLoading = (state) => state.questions.loading;

export const selectModerateQuestions = (state) =>
  state.questions.moderateQuestions;

export const selectQuestion = createSelector(
  [getQuestionSlice, (state, id) => id],
  (slice, id) => questionsSelectors.selectById(slice, id),
);

export const selectSelectedQuestionId = (state) =>
  getQuestionSlice(state).selectedQuestionId;

export const selectShownStates = (state) => getQuestionSlice(state).shownStates;

export const selectQuestionToolMode = (state) => {
  return state.questions.questionToolMode;
};

export const selectIsSpeakerMode = (state) =>
  state.questions.questionToolMode === "speaker";

export const selectShowDeletedQuestions = (state) => {
  return state.questions.showDeletedQuestions;
};

export const selectPushedQuestions = (state) => {
  return state.questions.pushedQuestions;
};

export const selectShowQuestionForm = (state) => {
  return state.questions.showQuestionForm;
};

// actions
export const {
  addQuestion,
  setSortByVotes,
  updateQuestionToolMode,
  toggleShowDeletedQuestions,
  toggleShowQuestionForm,
  updateQuestion,
  setSelectedQuestionId,
  setLockedQuestionId,
} = questionsSlice.actions;

// reducers
export default questionsSlice.reducer;
